.with-aside-layout {
	@media screen and (min-width: 767px) {
		padding-top: 80px;
		padding-bottom: 40px;
	}
	.page-title-block {
		padding-bottom: 21px;
		margin-bottom: 32px;
		background: $btn-cyan;
		img {
			max-width: 100%;
			display: block;
			border-bottom: 1px solid $white;
		}
	}
	@media screen and (max-width: 1023px) {
		.page-title-block {
			padding-bottom: 12px;
		}
	}
	@media screen and (max-width: 767px) {
		.page-title-block {
			display: none;
		}
	}
	@media screen and (min-width: 768px) {
		.aside-border {
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				width: 1px;
				height: 100%;
				background: #cecece;
				margin-right: -15px;
			}
		}
	}
	@media screen and (min-width: 1024px) {
		.aside-border {
			&:after {
				margin-right: -21px;
			}
		}
	}
	.service-block {
		margin-top: 40px;
		.block-title {
			font-size: 15px;
		}
		.learn-more {
			span {
				font-size: 20px;
				font-weight: 500;
				line-height: 24px!important;
				color: $white;
				text-transform: uppercase;
			}
			padding-right: 16px;
			padding-left: 16px;
			background: $main-cyan;
		}
	}
	.price-wrapper {
		border-left: 1px solid $white;
		box-sizing: content-box;
		height: 100%;
	}
	.price-quote {
		padding: 20px 16px 20px 16px;
		background: url(../images/elements/quote-bckgr.jpg);
		background-size: cover;
		color: $white;
		margin-bottom: 35px;
		.quote-title {
			display: block;
			text-transform: uppercase;
			font-weight: 700;
			margin-bottom: 10px;
			font-size: 16px;
		}
		.quoate-text {
			display: block;
			font-size: 14px;
			line-height: 1.2em;
			margin-bottom: 18px;
		}
		.quote-link {
			@include transition(all .4s ease);
			display: block;
			font-size: 14px;
			padding: 7px 6px 7px 14px;
			text-transform: uppercase;
			background: $orange;
			color: $white;
			font-weight: 500;
			position: relative;
			line-height: 1;
			&:before {
				content: '';
				height: 100%;
				width: 2px;
				background: $white;
				right: 15%;
				top: 0;
				display: block;
				position: absolute;
				border-left: 1px solid $black;
				opacity: .2;
			}
			&:hover {
				background: $btn-get-hover

			}
		}
	}
	@media screen and (max-width: 1023px) {
		.price-quote {
			padding: 14px 16px 16px 14px;
			margin-bottom: 26px;
			.quote-title {
				font-size: 11px;
				margin-bottom: 10px;
			}
			.quoate-text {
				font-size: 10px;
				margin-bottom: 10px;
			}
			.quote-link {
				font-size: 10px;
				font-weight: 700;
				padding: 6px 8px;
				&:after {
					margin-right: 5px;
				}
			}
		}
	}
	//-------------------./Price-quote-------------------------
	.aside-menu {
		margin-bottom: 20px;
		border-left: 1px solid #cecece;
		ul {
			list-style: none;
			li {
				font-weight: 700;
				border-bottom: 1px solid $submenu-border-grey;
				line-height: 1;
				a {
					padding: 10px 20px;
					padding-top: 11px;
					font-weight: 700;
					display: block;
					color: $menu-font-hover;
					text-decoration: none;
					@include transition(all .4s ease);
				}
				ul {

					li {
						padding-left: 20px;
						position: relative;
						@include transition(all .4s ease);
						&:first-child {
							background: inherit;
							color: inherit;
							text-transform: none;
							padding-top: 0;
							padding-bottom: 0;
						}
						&:last-child {
							border: none;
						}
						&:before {
							position: absolute;
							left: 0;
							bottom: 0;
							top: 0;
							margin: auto;
							content: '';
							width: 12px;
							height: 12px;
							display: block;
							background: $submenu-border-grey;
							margin-left: 20px;
						}
						a {
							font-size: 14px;
							font-weight: 400;
							padding: 11px 16px;
							padding-left: 20px;
							padding-right: 11px;
							line-height: 1;
							.no-touchevents &:hover {
								background: white;
								color: $curent-menu-hover;
								text-decoration: none;
							}
						}
					}
					.current-menu-item {
						background: $link-cyan;
						&:before {
							background: $white;
						}
						a {
							color: $white;
							text-decoration: none;
							@include transition(none);
						}
						&:first-child {
							background: $link-cyan;
						}
						.no-touchevents &:hover {
							background: $white;
							a {
								background: inherit;
    							color: inherit;
    							@include transition(none);
							}
							&:before {
								background: $link-cyan;
							}
						}
					}
				}
				a {
					.no-touchevents &:hover {
						background: $link-cyan;
						color: $white;
					}
				}
				&:first-child {
					padding: 12px 20px;
					background: $aside-bckgr;
					color: $white;
					font-size: 16px;

					text-transform: uppercase;
				}
			}
			.current-menu-ancestor {
				a {
					background: $link-cyan;
					color: $white;
				}
				ul {
					border-top: 1px solid $submenu-border-grey;
					a {
						background: inherit;
						color: inherit;
					}
					li {
						 &:hover {
							&:before {
							background: $btn-cyan;
							@include transition(all .4s ease);
							}
						}
					}
				}

			}
		}
	}
	@media screen and (max-width: 1023px) {
		.aside-menu {
			ul {
				li {
					font-size: 12px;
					&:first-child {
						padding: 8px 15px;
						padding-bottom: 10px;
						font-size: 12px;
					}
					a {
						padding: 8px 15px;
						font-size: 12px;
					}
					ul {
						li {
							padding: 0;
							padding-left: 15px;
							&:first-child {
								padding: 0;
								padding-left: 15px;
							}
							&:before {
								width: 8px;
								height: 8px;
								margin-left: 15px;
							}
							a {
								padding: 8px 14px;
								font-size: 10px;
							}
						}
					}
				}
			}
		}
	}
	//---------------------/.aside-menu-------------------
	.b-aside-feedback {
		padding: 20px 0 18px 18px;
		border-bottom: 1px solid $submenu-border-grey;
		p {
			font-size: 14px;
			font-weight: 300;
			line-height: 1.2;
		}
		span {
			font-size: 14px;
			font-weight: 500;
		}
		a {
			font-size: 14px;
			font-weight: 500;
		}
		a, span {
			line-height: 1;
		}
		.feedback-company {
			line-height: 1;
			margin-bottom: 1.2em;
		}
	}
	@media screen and (max-width: 1023px) {
		.b-aside-feedback {
			padding: 14px 0 10px 14px;
			p, span {
				font-size: 10px;
			}
			p {
				line-height: 1.2;
				margin-bottom: 1em;
			}
			.feedback-company {
				line-height: 1;
				margin-bottom: 1em;
			}
			a {
				font-size: 10px;
			}
		}
	}
	//---------------------/.b-aside-feedback-------------------
	.promo-block {
		padding: 20px 0 0 18px;
		img {
			max-width: 100%;
			border-bottom: 1px solid $white;
		}
		.promo-descr {
			padding: 15px;
			padding-top: 12px;
			background: $aside-bckgr;
		}
		.promo-title {
			display: block;
			color: $white;
			margin-bottom: 6px;
			font-weight: 700;
			font-size: 16px;
			line-height: 1.3;
		}
		a {
			line-height: 1;
			color: $white;
			font-weight: 500;
			font-size: 14px;
			&:hover {
				text-decoration: underline;
			} 
			&:active {
				text-decoration: none;
			}

		}
	}
	@media screen and (max-width: 1023px) {
		.promo-block {
			padding: 14px 0 0 14px;
			.promo-descr {
				padding: 10px;
			}
			.promo-title {
				font-size: 12px;
				margin-bottom: 0;
			}
			a {
				font-size: 10px;
			}
		}
	}
	//-----------------/.Promo-block---------------
}
@media screen and (min-width: 767px) and (max-width: 1023px) {
	.with-aside-layout {
		padding-top: 60px;
	}
	.b-aside {
		padding-left: 14px;
	}
}
@media screen and (max-width: 767px) {
	.with-aside-layout {
		padding-top: 50px;
		.price-quote,
		.aside-menu {
			display: none;
		}
	}
}