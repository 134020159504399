.home-slider-wrapper {
	position: relative;
	.home-slider {
		background: #001d22;
		opacity: 0;
		.home-slider__item {
			text-align: center;
			img {
				display: inline-block;
				max-width: 100%;
			}
		}
	}
	.slick-dots {
		padding: 0;
		list-style: none;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		text-align: center;
		margin-bottom: 24px;
		li {
			display: inline-block;
			span {
				background: $white;
				display: inline-block;
				outline: none;
				font-size: 0;
				border-radius: 50%;
				width: 18px;
				height: 18px;
				margin-right: 4px;
				opacity: 0.3;
			}
			&:last-child {
				span {
					margin: 0;
				}
			}
		}
		.slick-active {
			display: inline-block;
			span {
				opacity: 1;
			}
		}
	}
	//-----------Slick-dots----------------
	.slider-descr {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		text-align: center;
		h3 {
			color: $white;
			margin-top: 38px;
			margin-bottom: 15px;
			font-size: 42px;
			line-height: 42px;
			text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.35);
		}
		p {
			color: $white;
			font-weight: 300;
			font-size: 14px;
			line-height: 14px;
		}
	}
	@media screen and (max-width: $screen-lg) {
		.slider-descr {
			h3 {
				margin-top: 25px;
				font-size: 30px;
				line-height: 30px;
			}
		}
	}
	@media screen and (max-width: 1023px) {
		.slick-dots {
			margin-bottom: 15px;
		}
		.slider-descr {
			h3 {
				margin-top: 15px;
				font-size: 20px;
				line-height: 20px;
			}
			p {
				margin-top: .5em;
			}
		}
	}
	@media screen and (max-width: $screen-sm) {
		height: 20px;
		overflow: hidden;
		margin-bottom: 20px;
		.slider-descr {
			display: none
		}
		.slick-dots {
			display: none!important;
		}
	}
}
//--------------./Slider-----------------------
.home-layout {
	position: relative;
	padding: 50px 0 70px;
	.home-title,
	.home-quote {
		margin-top: 10px;
	}
	.home-title {
		p {
			line-height: 1.5;
		}
		a:hover {
			text-decoration: none;
			color: $hover;
		}
	}
	.block-title {
		display: block;
		font-size: 16px;
		padding: 10px 12px 10px 20px;
		background: $main-cyan;
		color: $white;
		font-weight: 700;
		text-transform: uppercase;
	}
	.price-quote {
		a {
			color: inherit;
		}
		.quote-body {
			padding: 20px 18px;
			background: $light-grey;
			p {
				font-size: 14px;
				line-height: 1.4em;
				margin-bottom: 1.7em;
			}
			a {
				display: block;
				color: $white;
				text-transform: uppercase;
				background: $orange;
				font-weight: 700;
				font-size: 14px;
				padding: 8px 14px;
				line-height: 1;
				letter-spacing: .001em;
				&:before {
					content: '';
					height: 100%;
					width: 2px;
					background: $white;
					right: 12%;
					top: 0;
					display: block;
					position: absolute;
					border-left: 1px solid $black;
					opacity: .2;
				}
				&:after {
					margin-right: 4%;
				}
			}
			a:hover {
				background: $btn-get-hover;
				text-decoration: none;
				transition: 0.5s;
			}
		}
	}
	.service-block {
		margin-top: 65px;
		word-wrap: break-word;
		.b-learn-more {
			position: relative;
			background: $main-cyan;
			img {
				width: 100%;
				border-bottom: 1px solid $main-cyan;
				display: block;
			}
			.b-learn__text {
				position: absolute;
				padding: 40px 10px 10px 20px;
				font-size: 20px;
				font-weight: 500;
				text-transform: uppercase;
				color: $white;
				line-height: 1.2em;
				top: 0;
				left: 0;
			}
		}
	}
	@media screen and (max-width: 1023px) {
		.service-block {
			.b-learn-more {
				.b-learn__text {
					font-size: 15px;
					padding-top: 15px;
				}
			}
		}
	}
	@media screen and (max-width: $screen-sm) {
		.service-block {
			.b-learn-more {
				.b-learn__text {
					font-size: 20px;
					padding-top: 20px;
				}
			}
		}
	}
	@media screen and (max-width: $screen-xs) {
		.service-block {
			.b-learn-more {
				.b-learn__text {
					font-size: 25px;
					padding-top: 35px;
				}
			}
		}
	}
	//---------------./Service-block--------------------
	.service-img {
		width: 100%;
		display: block;
		border-bottom: 1px solid $white;
	}
	a.block-title {
		@include transition(all .4s ease);
	}
	a.block-title:hover {
		background: $sevices-hover;
		text-decoration: none;
	}
	a.show-more :hover {
		background: $sevices-hover;
		text-decoration: none;
	}
	.service-links {
		list-style: none;
		li {
			@include transition(all .4s ease);
			line-height: 1;
			border-bottom: 1px solid $submenu-border-grey;
			position: relative;
			a {
				display: block;
				padding: 12px 20px;
				text-decoration: none;

				&:hover {
					color: $hover;
				}
			}
			&:before {
				@include transition(all .4s ease);
				position: absolute;
				left: 0;
				bottom: 0;
				top: 0;
				margin: auto;
				content: '';
				width: 12px;
				height: 12px;
				display: block;
				background: $submenu-border-grey;
			}
			&:hover {
				background: $submenu-hover-bckgr;
				&:before {
					background: $service-links-li-before;
				}
			}
		}
	}
	.client-feedback {
		border-bottom: 1px solid $submenu-border-grey;
		margin-bottom: 1em;
		p {
			font-weight: 300;
			line-height: 1.5;
			&:not(.feedback-company) {
				margin-top: 1.2em;
				margin-bottom: .7em;
			}
		}
		span {
			font-weight: 500;
			font-size: 14px;
			line-height: 1;
		}
		a {
			text-decoration: none;
			&:hover {
				color: $link-cyan;
			}
		}
		.feedback-company {
			font-size: 14px;
			line-height: 1;
			margin-bottom: 1.4em;
		}
	}
	a:hover {
		text-decoration: none;
		transition: 0.4s;
	}
	//------------------./Home elements--------------
}
.price-quote-mobile {
	display: none;
	background: $main-cyan;
	font-size: 14px;
	font-weight: 700;
	text-align: center;
	color: $white;
	line-height: 42px;
	margin-bottom: 20px;
	text-transform: uppercase;
	z-index: 1;
	position: relative;
	a {
		text-transform: uppercase;
		padding: 1px 8px;
		font-size: inherit;
		font-weight: inherit;
		color: inherit;
		background: $orange;
		margin-left: 10px;
		
	}

}
@media screen and (max-width: 767px) {
	.home-layout {
		padding-top: 0;
		padding-bottom: 40px;
		.price-quote {
			margin-top: 20px;
		}
		.service-img {
			width: 100%;
		}
		.fixed {
			position: fixed;
		}
	}
	.price-quote-mobile {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		display: block;
	}
}
@media screen and (max-width: $screen-xs) {
	.col-xs-6 {
		width: 100%;
	}
	.home-layout {
		.service-img {
			max-width: 100%;
		}
	}
}