.phone-icon {
	display: inline-block;
	width: 18px;
	height: 18px;
	background: url(../images/icons/phone.jpg);
	background-size: cover;
}
.social-icon {
	display: inline-block;
	height: 25px;
	width: 25px;
	background-size: cover;
}
.inst {
	background: url(../images/icons/inst.png);
}
.tweet {
	background: url(../images/icons/tweet.png);
}
.fb {
	background: url(../images/icons/fb.png);
}
.plus-icon {
	width: 8px;
	height: 2px;
	background: $black;
	display: inline-block;
	position: relative;
	&:before {
		content: '';
		width: 2px;
		height: 8px;
		background: $black;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		margin-bottom: -3px;
	}
}
.quote-icon-cyan {
	position: relative;
	&:after {
		content: '';
		display: block;
		vertical-align: middle;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		background: url(../images/icons/raquo-cyan.png);
		width: 9px;
		height: 7px;
		background-size: cover;
		margin-right: 10px;
	}
}
.quote-icon-inline {
	&:after {
		content: "\bb";
		display: inline-block;
		margin-left: 2px;
		font-size: 1.2em;
		color: inherit;
	}
}
.quote-icon-white {
	position: relative;
	&:after {
		content: '';
		display: block;
		vertical-align: middle;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		background: url(../images/icons/raquo-white.png);
		width: 9px;
		height: 7px;
		background-size: cover;
		margin-right: 10px; 
	}
}
.fb-blog-icon {
	background-image: url(../images/icons/fb-blog.jpg);
}
.tw-blog-icon {
	background-image: url(../images/icons/tw-blog.jpg);
}
.linkedin-blog-icon {
	background-image: url(../images/icons/lnin-blog.jpg);
}
.tumbler-blog-icon {
	background-image: url(../images/icons/tmbl-blog.jpg);
}
.gplus-blog-icon {
	background-image: url(../images/icons/gpls-blog.jpg);
}
.pinterest-blog-icon {
	background-image: url(../images/icons/pntrs-blog.jpg);
}
.mail-blog-icon {
	background-image: url(../images/icons/mail-blog.jpg);
}