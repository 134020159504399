.mobile-menu {
	position: absolute;
	-webkit-transform: translate3d(0,-120%,0);
    -ms-transform: translate3d(0,-120%,0);
    transform: translate3d(0,-120%,0);
    width: 100%;
    background: $white;
    z-index: 2;
    -webkit-box-shadow: 0px 0px 6px -1px rgba(0,0,0,0.59);
	-moz-box-shadow: 0px 0px 6px -1px rgba(0,0,0,0.59);
	box-shadow: 0px 0px 6px -1px rgba(0,0,0,0.59);
	overflow: hidden;
	opacity: 0;
	transition: all .4s ease;
    -webkit-transition: -webkit-transform .4s ease;
    ul {
    	li {
            position: relative;
    		a {
    			font-size: 18px;
    			font-weight: 700;
    			color: $menu-font-hover;
    			display: block;
    			padding: 10px 15px;
    			text-decoration: none;
    			position: relative;
    			&:after {
    				position: absolute;
    				content: '';
    				width: 100%;
    				height: 1px;
    				bottom: 0;
    				left: 0;
    				background: $mobile-menu-border;
    			}
    		}
            .submenu-link {
                position: absolute;
                width: 20%;
                height: 40px;
                right: 0;
                top: 0;
                z-index: 5;
                &:after {
                    display: none;
                }
            }
    	}
    	ul {
    		display: none;
    	}
    }
    .has-children {
    	a {
    		&:after {
    			margin-left: 15px;
    		}
    		font-weight: 500;
    		font-size: 14px;
    	}
    	.has-children {
    		a {
    			font-weight: 300;
    			font-size: 14px;
    			&:before {
					content: '';
					width: 10px;
					height: 10px;
					display: inline-block;
					background: $submenu-border-grey;
					margin-right: 4px;
				}
    		}
    	}
    }
    .menu-item-has-children {
    	.plus-icon {
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			margin-right: 20px;
		}
    }
    .list-item-opened {
    	position: relative;
		.plus-icon {
			&:before {
				display: none;
			}
		}
		&:after {
			margin-left: 15px;
		}
	}
}
.open-menu {
	-webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    transition: all .4s ease;
    -webkit-transition: -webkit-transform .4s ease;
    opacity: 1;
}
.close-menu {
	-webkit-transform: translate3d(0,-120%,0);
    -ms-transform: translate3d(0,-120%,0);
    transform: translate3d(0,-120%,0);
}
