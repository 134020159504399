@media screen and (min-width: 1025px) {
	.form-layout {
		padding-bottom: 75px;
		.aside-menu {
			margin-top: 0;
			.b-aside-feedback {
				&:first-child {
					padding-top: 0;
				}
			}
		}
	}
	.form-page-title {
		margin-bottom: 34px;
		h1 {
			font-size: 32px;
		}
		p {
			font-size: 18px;
			margin-bottom: 28px;
		}
	}
}
.form-wrap {
	&:after {
		content: '';
		display: table;
		clear: both;
	}
	.form-col {
		width: 50%;
		float: left;
	}
	.left-col {
		padding-right: 15px;
	}
	.right-col {
		padding-left: 15px;
	}
	.full-width {
		width: 100%;
	}
	.form-addit-info {
		padding-right: 80px;
		.form-capcha {
			margin-top: 20px;
		}
	}
	.form-input {
		margin-bottom: 23px;
		label {
			display: inline-block;
			font-size: 18px;
			font-weight: 500;
			color: $menu-font-hover;
			margin-bottom: 10px;
		}
		input[type="text"],
		input[type="tel"],
		input[type="email"] {
			display: block;
			height: 44px;
			width: 100%;
			border-radius: 5px;
			border: 1px solid $submenu-border-grey;
			outline: none;
			-webkit-appearance: none;
			font-size: 16px;
			font-weight: normal;
			padding: 5px 15px;
		}
		.hidden-input[type="text"] {
			display: none;
		}
		input[type="radio"] {
			display: none;
		}
		textarea {
			display: block;
			width: 100%;
			border-radius: 5px;
			border: 1px solid $submenu-border-grey;
			outline: none;
			-webkit-appearance: none;
			height: 165px;
			resize: none;
			padding: 20px;
			font-size: 16px;
			font-weight: normal;
		}
		input.error {
			border: 1px solid #d13939;
		}
		.wpcf7-list-item-label {
			display: none;
		}
	}
	.custom-dropdown {
		position: relative;
		display: block;
	}

	.custom-dropdown {
		select {
			outline: none;
			width: 100%;
			background-color: $white;
			color: $menu-font-hover;
			font-size: 18px;
			padding: .5em;
			padding-right: 2.5em;	
			border: 0;
			margin: 0;
			border-radius: 5px;
			border: 1px solid $submenu-border-grey;
			text-indent: 0.01px;
			text-overflow: '';
			cursor: pointer;
			-webkit-appearance: button;
			&::-ms-expand {
			    display: none;
			}
		}
		&:before,
		&:after {
			content: "";
			position: absolute;
			pointer-events: none;
		}
		&:after {
			content: "\25b6";
			height: 1em;
			font-size: 1.2em;
			line-height: 1;
			right: 0.6em;
			top: 50%;
			margin-top: -.5em;
			color: $black;
		}
		&:before {
			width: 2.8em;
			right: 0;
			top: 0;
			bottom: 0;
			border-radius: 0 3px 3px 0;
			background-color: $white;
			border: 1px solid $submenu-border-grey;

		}
		select[disabled] {
			color: rgba(0,0,0,.3);
			&:after{
				color: rgba(0,0,0,.1);
			}
		}
	}
	.option-input,
	.wpcf7-list-item {
		input {
			display: none;
			&:checked + label {
				&:before {
					background: $link-cyan;
				}
			}
		}
		label {
			cursor: pointer;
			font-weight: 300;
			&:before {
				content: '';
				height: 14px;
				width: 14px;
				margin-right: 10px;
				display: inline-block;
				border-radius: 50%;
				border: 1px solid $black;
				background: rgb(255,255,255);
				background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(236,236,236,1) 50%, rgba(255,255,255,1) 100%);
				background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,1)), color-stop(50%,rgba(236,236,236,1)), color-stop(100%,rgba(255,255,255,1)));
				background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(236,236,236,1) 50%,rgba(255,255,255,1) 100%);
				background: -o-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(236,236,236,1) 50%,rgba(255,255,255,1) 100%);
				background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(236,236,236,1) 50%,rgba(255,255,255,1) 100%);
				background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(236,236,236,1) 50%,rgba(255,255,255,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=0 );
			}
		}
	}
	span.wpcf7-list-item {
		display: block;
		margin: 0;
	}
	.required {
		label {
			&:after {
				content: "*";
				color: $main-cyan;
				display: inline-block;
			}
		}
	}
}
.contact-form {
	margin-top: 60px;
	.form-addit-info {
		padding-right: 0;
		span, a {
			font-size: 15px;
			color: $menu-font-hover;
		}
		span {
			display: block;
		}
		.form-input {
			width: 33.3%;
			float: left;
		}
		.company-contacts {
			width: 33.3%;
			float: left;
			padding-left: 70px;
		}
		.additional-contacts {
			width: 33.3%;
			float: left;
			padding-left: 55px;
			.contact {
				padding-bottom: 18px;
				margin-bottom: 15px;
				border-bottom: 1px solid $submenu-border-grey;
				&:last-child {
					border: none;
				}
			}
		}
		.contact-title {
			display: block;
			font-size: 18px;
			font-weight: 500;
			color: $menu-font-hover;
			margin-bottom: 14px;
			line-height: 1.3;
		}
		.form-capcha {
			margin-top: 0;
		}
	}
}
.send-form {
	margin-top: 20px;
	color: $white;
	background: $link-cyan;
	border-radius: 0;
	padding: 11px 17px;
	@include transition(background .4s ease);
	.no-touchevents &:hover {
		background: #054852;
	}
}
@media screen and (max-width: 1023px) {
	.form-wrap {
		.form-input {
			label {
				font-size: 16px;
			}
			input:not([type="checkbox"]) {
				font-size: 14px;
			}
			textarea {
				font-size: 14px;
			}
		}
		.custom-dropdown {
			select {
				font-size: 16px;
			}
		}
	}
	.contact-form {
		margin-top: 30px;
		.form-addit-info {
			.contact-title {
				font-size: 16px;
			}
			span, a {
				font-size: 13px;
			}
			padding-right: 0;
			.form-input {
				width: 33.3%;
				float: left;
			}
			.company-contacts {
				width: 33.3%;
				float: left;
				padding-left: 15px;
			}
			.additional-contacts {
				width: 33.3%;
				float: left;
				padding-left: 15px;
			}
		}
	}
}
@media screen and (max-width: $screen-sm) {
	.form-page-title {
		h1 {
			font-size: 22px;
		}
		p {
			font-size: 14px;
		}
	}
	.form-wrap {
		.form-addit-info {
			padding-right: 50px;
		}
	}
	.contact-form {
		margin-top: 20px;
		.form-addit-info {
			padding-right: 0;
			.form-input {
				width: 100%;
				float: none;
			}
			.company-contacts {
				width: 50%;
				float: left;
				padding-left: 0;
			}
			.additional-contacts {
				width: 50%;
				float: left;
				padding-left: 0;
			}
		}
	}
}
@media screen and (max-width: $screen-xs) {
	.form-wrap {
		.form-col {
			width: 100%;
		}
		.left-col,
		.right-col {
			padding: 0;
		}
		.form-input {
			margin-bottom: 15px;
		}
	}
	.contact-form {
		.form-addit-info {
			.company-contacts {
				width: 100%;
				float: none;
				padding-left: 0;
				margin-bottom: 20px;
			}
			.additional-contacts {
				width: 100%;
				float: none;
				padding-left: 0;
			}
		}
	}
}