.solutions {
	padding-bottom: 60px;
	.service-block {
		margin-top: 0;
		margin-bottom: 40px;
	}
	.b-serv-description {
		margin-bottom: 45px;
		h1 {
			margin-bottom: .8em;
		}
		p {
			margin-bottom: 1em;
		}
	}
} 