.head-line {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	display: block;
	height: 30px;
	background-image: url(../images/elements/head-line.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-color: #001f25;
}
@media screen and (max-width: $screen-md) {
	.head-line {
		height: 25px;
	}
}
@media screen and (max-width: 767px) {
	.head-line {
		display: none;
	}
}
.modal-wnd {
	display: none;
	padding: 30px 40px 40px 40px;
	position: relative;
	.close-btn {
		position: absolute;
		top: 0;
		right: 0;
		line-height: 1;
		color: #333;
		font-size: 11px;
		margin: 15px 15px 0 0;
	}
	.modal-wnd__body {
		max-width: 100%;
		img {
			max-width: 100%;
		}
	}
	h1 {
		font-size: 24px;
		color: #333;
		margin-bottom: 30px;
	}
}
.fancybox-skin {
	border-radius: 0;
	background-color: #007a8c;
	.fancybox-outer {
		background-color: $white;
	}
}