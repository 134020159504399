body {
	font-family: "Roboto", Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
h1,h2,h3,h4,h5,h6 {
	font-family: "Roboto", sans-serif;
	color: $menu-font-hover;
	margin-top: 0;
	font-weight: 500;
	margin-bottom: .6em;
}
h1 {
	font-size: 44px;
	line-height: 1em;
}
p {
	font-family: "Roboto";
	font-size: 16px;
	font-weight: normal;
	line-height: 1.4;
	color: $menu-font-hover;
	margin-top: 0;
	margin-bottom: 1.4em;
}
a {
	font-size: 15px;
	font-weight: normal;
	color: $link-cyan;
	.no-touch &:hover {
		color: $hover;
	}
}

.menu, .footer-menu, .aside-menu, .mobile-menu  {
	ul, ol {
		margin: 0;
	}
}

ul, ol {
	&.service-links,
	&.aside-menu,
	&.b-serv-list,
	&.support-block,
	&.footer-phones,
	&.support-category {
		margin: 0;
        padding: 0;
	}
} 

ul, ol {
	margin: 0 0 20px 20px;
	font-family: "Roboto";
	line-height: 1.4;
	font-weight: 300;
	color: $menu-font-hover;
	padding: 0;
}

.no-overflow {
	overflow: hidden;
}
@media screen and (max-width: 1023px) {
	h1 {
		font-size: 23px;
	}
	p {
		font-size: 13px;
	}
}

.industry-page-title {
	margin-bottom: 30px;
}
