.b-serv-article {
	margin-bottom: 40px;
	padding-bottom: 30px;
	h3 {
		font-size: 24px;
	}
	&:last-child {
		margin-bottom: 0;
	}
	.article-text-col {
		width: 70%;
		float: left;
		padding-right: 40px;
		p {
			font-size: 15px;
			font-weight: 300;
			line-height: 1.3;
		}
		a {
			font-size: 14px;
		}
	}
	.article-img-col {
		width: 30%;
		float: left;
		.article-img {
			background: $link-cyan;
			padding-bottom: 15px;
			img {
				width: 100%;
				display: block;
				border-bottom: 1px solid $white;
			}
		}
	}
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}
@media screen and (max-width: 1023px) {
	.b-serv-article {
		margin-bottom: 20px;
		h3 {
			font-size: 18px;
		}
		.article-text-col {
			width: 71%;
			padding-right: 30px;
			p {
				font-size: 11px;
				margin-bottom: .8em;
				line-height: 1.4;
			}
			a {
				font-size: 10px;
			}
		}
		.article-img-col {
			width: 29%;
			.article-img {
				padding-bottom: 10px;
			}
		}
	}
}
@media screen and (max-width: 767px) {
	.b-serv-article {
		.article-img-col  {
			display: none;
		}
		.article-text-col {
			p,a {
				font-size: 14px;
			}
			p {
				line-height: 1.3;
			}
		}
	}
}
@media screen and (max-width: $screen-xs) {
	.b-serv-article {
		.article-text-col {
			width: 100%;
			padding: 0;
		}
	}
}