.layout-footer {
	background: $light-grey;
	padding-top: 35px;
	ul, ol {
		margin: 0;
	}
	&:after{
		content: '';
		display: block;
		width: 100%;
		height: 4px;
		background-color: #60c7de;
		background-image: url(../images/elements/line-breaker.jpg);
		background-repeat: no-repeat;
		background-position: right;
	}
	.col-title {
		font-size: 12px;
		font-weight: 700;
		color: $footer-title;
		text-transform: uppercase;
		display: inline-block;
		line-height: 1.6em;
		margin-bottom: .3em;
	}
	@media screen and (min-width: 1025px) {
		.footer-first-col {
			width: 11.66667%;
			padding-right: 0;
		}
		.footer-second-col {
			width: 63.33333%;
			.menu-column {
				&:first-child {
					padding-left: 10px;
					width: 22%;
				}
				&:nth-child(2) {
					width: 27%;
				}
				&:nth-child(3) {
					width: 26.5%;
				}
				&:nth-child(4) {
					width: 24.5%;
				}
			}
		}
		.footer-third-col {
			left: 63.33333%;
			padding-left: 0;
		}
	}
	
	.col-body,
	.footer-menu li {
		address {
			font-style: normal;
			font-size: 11px;
			color: $footer-font;
			font-weight: 300;
			margin-bottom: 20px;
		}
		list-style: none;
		li {
			font-weight: 300;
			font-size: 11px;
			color: $footer-font;
			line-height: 1.7em;
			a {
				font-weight: inherit;
				font-size: inherit;
				color: inherit;
			}
			&:first-child {
				margin-top: .3em;
			}
		}
		img {
			max-width: 100%;
			display: block;
		}
		.footer-phones {
			list-style: none;
			margin-bottom: 10px;
			li {
				font-weight: 400;
			}
		}
	}
	.footer-menu {
		.col-title-parent {
			a:first-child {
				font-size: 12px;
				font-weight: 700;
				color: $footer-title;
				line-height: 1;
				margin-bottom: .5em;
				text-transform: uppercase;
			}
			ul {
				li {
					a:first-child,
					a {
						font-weight: inherit;
						font-size: inherit;
						color: inherit;
						text-transform: none;
					}
				}
				.col-title-parent {
					margin-top: 0;
					a:first-child {
						font-size: 12px;
						font-weight: 700;
						color: $footer-title;
						line-height: 1;
						margin-bottom: .5em;
						text-transform: uppercase;
					}
				}
			}
		}
	}
	.about-company {
		.col-title {
			text-transform: none;
		}
		img {
			max-width: 86px;
			box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
		}
	}
	.footer-menu {
		list-style: none;
		&:after {
			content: '';
			display: table;
			clear: both;
		}
		li {
			width: 25%;
			float: left;
			li {
				width: auto;
				float: none;
			}
		}
	}
	@media screen and (max-width: 767px) {
		.footer-menu,
		.about-company {
			display: none;
		}
	}
	//---------./Footer-menu----------------
	.widgetGuts {
		h3 {
			display: none;
		}
	}
	.newsletter,
	#frm-subscriptionFront {
		border-radius: 6px;
		position: relative;
		padding-right: 45px;
		overflow: hidden;
		margin: 5px 0 20px;
		table {
			width: 100%;
			.required {
				th {
					display: none;
				}
			}
			tr:not(.required) {
				th {
					display: none;
				}
			}
		}
		.subscriber-mail,
		#frm-email {
			border-radius: 6px;
			outline: none;
			-webkit-appearance: none;
			border: 1px solid #c9c9c9;
			font-size: 12px;
			font-style: italic;
			font-weight: 300;
			color: $menu-font-color;
			padding: 10px 0 10px 12px;
			max-height: 35px;
			width: 100%;
			background: #fbfbfb;
			position: relative;
		}
		.error {
			border: 1px solid #d13939 !important;
			&::-webkit-input-placeholder {
			   color: #d13939;
			}
			&:-moz-placeholder { /* Firefox 18- */
			   color: #d13939;  
			}
			&::-moz-placeholder {  /* Firefox 19+ */
			   color: #d13939;  
			}
			&:-ms-input-placeholder {  
			   color: #d13939;  
			}
		}
		.newsletter-btn,
		.subscribeButton {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			background: $btn-cyan;
			color: $white;
			border-radius: 0;
			border: none;
			border-top: 1px solid #006270;
			border-bottom: 1px solid #006270;
			font-size: 12px;
			@include transition(background .4s ease);
			.no-touchevents &:hover {
				background: #054852;
			}
		}
	}
	@media screen and (max-width: 1023px) {
		.footer-sertificate {
			position: absolute;
		    left: 100%;
		    top: 0;
		}
	}
	@media screen and (max-width: $screen-sm) {
		.footer-sertificate {
			display: none;
		}
	}

	//---------./Newsletter----------------
	.footer-copyright {
		margin-top: 30px;
		line-height: 30px;
		padding: 10px 0;
		background: $black;
		&:after {
			display: table;
			content: '';
			clear: both;
		}
		.column {
			width: 50%;
			float: left;
		}
		.copyright {
			span {
				color: #bfbfbf;
				font-size: 12px;
				white-space: nowrap;
				&:first-child {
					&:after {
						content: '';
						height: 10px;
						width: 1px;
						background: #bfbfbf;
						display: inline-block;
						margin: 0 5px;
					}
				}
			}
		}
		.social-block {
			vertical-align: middle;
			text-align: right;
			a {
				margin-right: 4px;
				line-height: 30px;
				&:last-child {
					margin: 0;
				}
				i {
					vertical-align: middle;
				}
			}
		}
	}
	@media screen and (max-width: 767px) {
	.footer-copyright {
		.copyright {
				span {
					font-size: 11px;
					&:first-child {
						&:after {
							display: none;
						}
					}
				}
			}
		}
	}
	@media screen and (max-width: 660px) {
		.footer-copyright {
			.copyright {
				line-height: 14px;
			}
		}
	}

}
@media screen and (max-width: 767px) {
	.layout-footer {
		padding-top: 20px;
		.footer-copyright {
			margin-top: 0;
		}
	}
}