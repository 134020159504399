.layout-header {
	position: relative;
	padding: 20px 0 14px;
	color: $black;
	z-index: 3;
	background: $white;
	&:before {
		content: '';
		display: block;
		width: 100%;
		height: 4px;
		background-color: #60c7de;
		background-image: url(../images/elements/line-breaker.jpg);
		background-repeat: no-repeat;
		background-position: right;
		position: absolute;
		top: 0;
		left: 0;
	}
	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 14px;
		background: -moz-linear-gradient(top,  rgba(0,0,0,0.7) 0%, rgba(255,255,255,0) 100%);
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0.7)), color-stop(100%,rgba(255,255,255,0)));
		background: -webkit-linear-gradient(top,  rgba(0,0,0,0.7) 0%,rgba(255,255,255,0) 100%);
		background: -o-linear-gradient(top,  rgba(0,0,0,0.7) 0%,rgba(255,255,255,0) 100%);
		background: -ms-linear-gradient(top,  rgba(0,0,0,0.7) 0%,rgba(255,255,255,0) 100%);
		background: linear-gradient(to bottom,  rgba(0,0,0,0.7) 0%,rgba(255,255,255,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00ffffff',GradientType=0 );
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 9;
	}
	.logo {
		display: inline-block;
		img {
			max-width: 100%;
		}
	}
	.header-block {
		float: right;
		width: 72%;
		.header-contacts {
			line-height: 18px;
			height: 18px;
			margin-bottom: 20px;
			font-size: 12px;
			font-weight: 500;
			text-align: right;
			span {
				opacity: 0.7;
				vertical-align: top;
				&:not(.phone-number) {
					text-transform: uppercase;
					padding-left: 2px;
				}
			}
			a {
				color: $black;
				opacity: 0.7;
				display: inline-block;
				font-size: 12px;
				font-weight: 500;
				line-height: 18px;
				vertical-align: top;
				text-transform: uppercase;
				margin-left: 5px;
				&:first-child {
					opacity: 1;
				}
			}
			.phone-number {
				position: relative;
				padding: 0 8px;
				&:after {
					content: '';
					top: 0;
					right: 0;
					position: absolute;
					display: inline-block;
					height: 12px;
					width: 1px;
					background: $separator-grey;
				}
			}
		}
		.menu {
			padding-top: 12px;
			border-top: 1px solid $separator-light-grey;
			ul {
				font-size: 18px;
				font-weight: normal;
				text-align: justify;

				li {
					display: inline-block;
					position: relative;
					&:after {
						content: '';
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						margin: auto;
						display: block;
						height: 14px;
						width: 1px;
						background: #333;
					}
					&:last-child {
						width: 100%;
					    height: 0px;
					    visibility: hidden;
					    overflow: hidden;
					}
					&:nth-last-child(2) {
						&:after {
					    	display: none;
					    }
					}
					a {
						display: inline-block;
						text-decoration: none;
						color: $menu-font-color;
						font-size: 18px;
						border-bottom: 5px solid transparent;
						@include transition(border .4s ease);
					}
					//----------Submenu-----------
					ul {
						position: absolute;
						top: 100%;
						left: 0;
						min-width: 155px;
						width: 100%;
						display: none;
						font-size: 14px;
						font-weight: 700;
						z-index: 99;
						margin-top: -5px;
						border-top: 5px solid $main-cyan;
						li {
							text-align: left;
							display: block;
							&:after {
								display: none;
							}
							&.current-menu-item a {
								color: $menu-font-hover;
								background: $submenu-hover-bckgr;
							}
							a {
								display: block;
								padding: 8px 12px;
								border: 1px solid $submenu-border-grey;
								border-top: none;
								background: $white;
								font-size: 14px;
								font-weight: 700;
								@include transition(all .4s ease);
								.no-touchevents &:hover {
									color: $menu-font-hover;
									background: $submenu-hover-bckgr;
								}

							}
							&:last-child {
								width: auto;
							    height: auto;
							    visibility: visible;
							    overflow: visible;
							}
						}
					}
					.no-touchevents &:hover {
						a {
							color: $menu-font-hover;
							border-bottom: 5px solid $main-cyan;
						}
						ul {
							display: block;
							a {
								border: 1px solid $submenu-border-grey;
								border-top: none;
								color: $menu-font-color;
							}
						}
					}
				}
				.current-menu-ancestor {
					a {
						color: $menu-font-hover;
						border-bottom: 5px solid $main-cyan;
					}
				}
				.current-menu-item {
					a {
						color: $menu-font-hover;
						border-bottom: 5px solid $main-cyan;
					}
				}
			}
		}
		@media screen and (max-width: $screen-md) {
			.menu {
				ul {	
					li {
						a {
							font-size: 13px;
						}
						ul {
							font-size: 11px;
						}
					}
				}
			}
		}
		//--------------Header-menu-----------	
	}
	.mobile-menu-link {
		display: none;
		float: right;
		line-height: 40px;
		a {
			display: block;
		}
		.mobile-menu-btn {
			width: 23px;
			height: 3px;
			background: $menu-font-color;
			vertical-align: middle;
			display: inline-block;
			position: relative;
			&:before {
				content: '';
				position: absolute;
				bottom: 100%;
				left: 0;
				width: 23px;
				height: 3px;
				margin-bottom: 3px;
				background: $menu-font-color;
			}
			&:after {
				content: '';
				position: absolute;
				top: 100%;
				left: 0;
				width: 23px;
				height: 3px;
				margin-top: 3px;
				background: $menu-font-color;
			}
		}
	}
}
.no-shadow {
	&:after {
		top: auto;
		bottom: 0;
		background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(132,132,132,0.33) 100%);
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,1)), color-stop(100%,rgba(132,132,132,0.33)));
		background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(132,132,132,0.33) 100%);
		background: -o-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(132,132,132,0.33) 100%);
		background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(132,132,132,0.33) 100%);
		background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(132,132,132,0.33) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#54848484',GradientType=0 );
		height: 5px;
	}
}
@media screen and (max-width: $screen-md) {
	.layout-header {
		padding: 15px 0 5px;
		.logo {
			img {
				max-width: 140px;
			}
		}
		.header-block {
			.header-contacts {
				font-size: 9px;
				a {
					font-size: 9px;
				}
				margin-bottom: 10px;
			}
			.menu {
				padding-top: 6px;
				li {
					a {
						border-bottom: 5px solid transparent;
					}
				}
			}
		}
	}
}
@media screen and (max-width: $screen-sm) {
	.layout-header {
		padding: 10px 0 6px;
		.container {
			text-align: center;
		}
		.logo {
			img {
				max-width: 100%;
				max-height: 40px;
			}
		}
		.header-block {
			float: left;
			width: auto;
			.header-contacts {
				margin: 0;
				line-height: 40px;
				height: auto;
				i {
					vertical-align: middle;
				}
				span {
					display: none;
				}
				a {
					display: inline;
					opacity: 1;
					&:last-child {
						display: none;
					}
				}
			}
		}
		.menu {
			display: none;
		}
		.mobile-menu-link {
			display: block;
		}
	}
}