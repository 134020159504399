.blog-article-thumb {
	margin-bottom: 28px;
	&:last-child {
		margin-bottom: 0;
		p {
			margin-bottom: 0;
		}
	}
	h3 {
		font-size: 32px;
		font-family: "Roboto",sans-serif;
    	font-weight: 500;
    	line-height: 1;
    	margin-bottom: 0.5em;
    	a {
    		font-size: inherit;
			font-family: inherit;
	    	font-weight: inherit;
	    	line-height: inherit;
	    	margin-bottom: inherit;
	    	color: $menu-font-hover;
	    	&:active,
	    	&:focus,
	    	&:visited {
	    		text-decoration: none;
	    	}
    	}
	}
	date {
		font-size: 14px;
		line-height: 1;
		font-weight: 500;
		color: $link-cyan;
		margin-bottom: 0.9em;
    	display: block;
	}
	p {
		font-weight: 300;
		line-height: 1.4em;
		margin-bottom: 2.143em;
		font-size: 14px;
	}
	.read-more-link {
		font-size: 14px;
		font-weight: 500;
	}
}
@media screen and (max-width: $screen-md) {
	.blog-article-thumb {
		margin-bottom: 20px;
		h3 {
			font-size: 28px;
		}
		p {
			margin-bottom: 1.8em;
		}
	}
}
@media screen and (max-width: 767px) {
	.blog-article-thumb {
	margin-bottom: 18px;
		h3 {
			font-size: 22px;
		}
		date {
			font-size: 13px;
		}
		p {
			font-size: 13px;
		}
		.read-more-link {
			font-size: 13px;
		}
	}
}
//-------------Blog-article--------------------
.blog-article {
	margin-bottom: 0;
	h1 {
		margin-bottom: 0.4em;
	}
	h5 {
		font-size: 18px;
		font-weight: 400;
		line-height: 1;
		margin-bottom: 0.5em;
	}
	.article-data {
		padding-bottom: 30px;
		margin-bottom: 26px;
		font-size: 14px;
		line-height: 1;
		font-weight: 500;
		color: $link-cyan;
		letter-spacing: 0.1px;
		date {
			display: inline-block;
			margin-left: 5px;
		}
	}
	.article-image {
		font-size: 14px;
		color: $menu-font-hover;
		background: #f0f0f0;
		max-width: 300px;
		margin-bottom: 60px;
		img {
			max-width: 100%;
			height: auto;
			display: block;
		}
		span {
			display: block;
			padding: 14px;
		}
	}
	.float-right {
		float: right;
		margin-left: 26px;
	}
	.float-left {
		float: left;
		margin-right: 26px;
	}
	p {
		font-size: 14px;
		line-height: 1.4em;
		margin-bottom: 1.9em;
	}
	a {
		font-size: 14px;
		font-weight: 500;
		word-wrap: break-word;
	}
	.b-share {
		margin-top: 36px;
		background: #515151;
		padding: 0 25px;
		&:after {
			content: '';
			display: table;
			clear: both;
		}
		img {
			max-width: 100%;
			height: auto;
			display: block;
		}
		.column {
			width: 50%;
			float: left;
			line-height: 70px;
			color: $white;
			a {
				padding: 0 8px;
			}
		}
		.social-icon {
			display: inline-block;
			vertical-align: middle;
			width: 26px;
			height: 26px;
			background-size: cover;
		}
	}
}
@media screen and (max-width: $screen-md) {
	.blog-article {
		.article-data {
			padding-bottom: 25px;
			margin-bottom: 21px;
		}
		h5 {
			font-size: 16px;
		}
		.article-image {
			max-width: 250px;
			margin-bottom: 30px;
		}
		.b-share {
			margin-top: 0;
			.column {
				text-align: center;
				width: 100%;
				float: none;
				line-height: 50px;
			}
		}
	}
}
@media screen and (max-width: 767px) {
	.blog-article {
		.article-data {
			padding-bottom: 20px;
			margin-bottom: 16px;
		}
		h5 {
			font-size: 15px;
		}
		a,p {
			font-size: 13px;
		}
		p {
			margin-bottom: 1.2em;
		}
		.article-image {
			max-width: 200px;
			margin-bottom: 20px;
			span {
				font-size: 13px;
			}
		}
		.b-share {
			padding: 15px;
			.column {
				line-height: 30px;
				a {
					padding: 2px;
				}
			}
		}
	}
}
@media screen and (max-width: $screen-xs) {
	.blog-article {
		.article-data {
			padding-bottom: 15px;
			margin-bottom: 11px;
		}
		.article-image {
			max-width: 100%;
			margin-bottom: 15px;
			img {
				width: 100%;
			}
		}
		.float-right {
			float: none;
			margin-left: 0;
		}
		.float-left {
			float: none;
			margin-right: 0;
		}
	}
}
















