.support-page-title {
	margin-bottom: 30px;
	p {
		margin-bottom: 2em;
	}
}
.support-category {
	border-bottom: 1px solid $submenu-border-grey;
	padding-bottom: 36px;
	margin-bottom: 32px;
	&:last-child {
		padding-bottom: 0;
		margin-bottom: 0;
		border: 0;
	}
	li {
		list-style: none;
		display: block;
	}
	a {
		display: inline-block;
		font-size: 14px;
		line-height: 1.57em;
	}
	.sup-cat-title {
		font-family: "Roboto",sans-serif;
    	font-weight: 500;
    	font-size: 24px;
    	margin-bottom: 10px;
	}
}
@media screen and (max-width: 1023px) {
	.support-page-title {
		margin-bottom: 15px;
	}
	.support-category {
		padding-bottom: 18px;
		margin-bottom: 16px;
		a {
			font-size: 12px;
		}
		.sup-cat-title {
			font-size: 18px;
		}
	}
}