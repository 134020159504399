.industry-page-title {
	.title-text {
		padding-top: 40px;
		padding-right: 40%;
		padding-bottom: 1px;
		background-repeat: no-repeat, repeat-y;
		background-size: 100% auto, 100% auto;
		background-position: right bottom, top left;
		min-height: 460px;
		h1 {
			font-size: 38px;
			font-weight: 500;
			color: $menu-font-hover;
			margin-bottom: .8em;
		}
		p {
			font-size: 18px;
			font-weight: normal;
			color: $menu-font-hover;
		}
		.industry-logo {
			max-width: 35%;
			height: auto;
			display: block;
		}
	}
}
@media screen and (max-width: 1023px) {
	.industry-page-title {
		.title-text {
			padding-top: 25px;
			min-height: 340px;
			h1 {
				font-size: 28px;
			}
			p {
				font-size: 14px;
			}
		}
	}
}
@media screen and (max-width: 767px) {
	.industry-page-title {
		.title-text {
			min-height: 430px;
		}
	}
}
@media screen and (max-width: 580px) {
	.industry-page-title {
		.title-text {
			min-height: 330px;
			padding-top: 10px;
			h1 {
				font-size: 22px;
			}
			p {
				font-size: 13px;
			}
		}
	}
}
//--------/.industry-page-title---------
.service-categories {
	margin-top: 40px;
	margin-bottom: 50px;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 47.3%;
		height: 100%;
		width: 1px;
		background:	$industry-separator; 
	}
	.categories-col {
		width: 47.3%;
		float: left;
		h2 {
			font-size: 24px;
			font-weight: 500;
		}
		p {
			font-size: 15px;
			font-weight: 300;
			line-height: 1.3;
			&:last-child {
				margin-bottom: 0;
			}
		}
		&:first-child {
			padding-right: 35px;
		}
		&:last-child {
			padding-left: 55px;
			width: 52.7%;
		}
	}
	&:after {
		display: table;
		content: '';
		clear: both;
	}
}
@media screen and (max-width: 1023px) {
	.service-categories {
		.categories-col {
			h2 {
				font-size: 23px;
			}
			p {
				font-size: 13px;
			}
		}
	}
}
@media screen and (max-width: 540px) {
	.service-categories {
		margin-top: 20px;
		&:before {
			display: none;
		}
		.categories-col {
			width: 100%;
			margin-top: 10px;
			&:first-child,
			&:last-child {
				width: 100%;
				padding: 0;
			}
		}
	}
}
//--------/.service-categories---------
.customers-feedback {
	.feedback-title {
		h3 {
			font-size: 24px;
			font-weight: 500;
			padding: 13px 25px;
			color: $white;
			margin-bottom: 0;
			background: $aside-bckgr;
		}
		img {
			display: block;
			max-width: 100%;
			border-top: 1px solid $white;
		}
	}
	.feedback-item {
		margin-top: 10px;
		padding: 13px 35px;
		padding-top: 20px;
		background: $feedback-bckgr;
		p {
			font-weight: 300;
			line-height: 1.3;
			margin-bottom: 1em;
		}
		.feedback-author {
			display: block;
			font-size: 12px;
			font-weight: 500;
		}
		.feedback-company {
			display: block;
			font-size: 12px;
			font-weight: 300;
		}
	}
}
@media screen and (max-width: 540px) {
	.customers-feedback {
		.feedback-title {
			h3 {
				line-height: 1;
			}
		}
	}
}
//--------------/.customers-feedback------------