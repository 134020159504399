@import "partials/variable";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/variables.scss";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins.scss";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/normalize.scss";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/print.scss";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/glyphicons.scss";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/scaffolding.scss";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/grid.scss";
@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/responsive-utilities.scss";

@import "partials/base";
@import "partials/fonts";
@import "partials/icons";
@import "partials/mobile-menu";
@import "partials/slick.css";
@import "partials/buttons";
@import "partials/helper";

@import "blocks/footer";
@import "blocks/header";

@import "fancybox/jquery.fancybox.css";

@import "pages/home";
@import "pages/with-aside";
@import "pages/industry";
@import "pages/service";
@import "pages/form";
@import "pages/managed-services";
@import "pages/industry-solutions";
@import "pages/support";
@import "pages/blog";

