.b-serv-description {
	margin-bottom: 40px;
	h1 {
		font-size: 32px;
		margin-bottom: 1em;
	}
	h3 {
		font-size: 24px;
		font-weight: 500;
	}
	p {
		font-size: 18px;
		font-weight: 300;
		line-height: 1.3;
		margin-bottom: 2em;
	}
}
@media screen and (max-width: 1023px) {
	.b-serv-description {
		margin-bottom: 30px;
		h3 {
			font-size: 23px;
		}
		h1 {
			margin-bottom: 1em;
		}
		p {
			font-size: 13px;
			margin-bottom: 2.5em;
		}
	}
}
@media screen and (max-width: 767px) {
	.b-serv-description {
		margin-bottom: 20px;
		h3 {
			font-size: 18px;
		}
		h1 {
			font-size: 22px;
			margin-bottom: 1em;
		}
		p {
			font-size: 16px;
			margin-bottom: 1.5em;
		}
	}
}
.border-bot {
	border-bottom: 1px solid $submenu-border-grey;
}
.b-pricing {
	padding-bottom: 24px;
	margin-bottom: 36px;
	&:not(.border-bot) {
		padding-bottom: 0;
	}
	h1 {
		font-size: 32px;
		font-weight: 500;
		color: #333;
		margin-bottom: .8em;
	}
	h2 {
		font-size: 28px;
		font-weight: 500;
		color: #333;
	}
	h3 {
		font-size: 24px;
		font-weight: 500;
		color: #333;
	}
	p {
		font-size: 15px;
		font-weight: 300;
		line-height: 1.3;
	}
}
.price-table {
	background: $table-bckgr;
	padding: 15px;
	margin-bottom: 40px;
	&:after {
		content: '';
		display: table;
		clear: both;
	}
	.price-col {
		width: 25%;
		float: left;
		text-align: center;
		color: $table-font;
		font-size: 14px;
		&:first-child {
			border-left: 1px solid $table-border;
		}
		.price-cell {
			padding: 20px 30px;
			border: 1px solid $table-border;
			border-top: none;
			border-left: none;
			background: $table-cell-bckg;
			&:first-child {
				border-top: 1px solid $table-border;
				font-size: 17px;
				padding: 16px 30px;
			}
			&:nth-child(3) {
				padding-top: 14px;
				padding-bottom: 14px;
			}
			&:nth-child(4) {
				padding-top: 15px;
				padding-bottom: 15px;
			}
			&:nth-child(5) {
				padding: 14px 30px;
			}
		}
		.monthly-price {
			background: $table-bckgr;
			font-size: 52px;
			color: $table-cyan;
			font-weight: 700;
			letter-spacing: -0.04em;
			padding: 18px 70px 18px 30px;
			span {
				position: relative;
				line-height: 1;
				&:before {
					letter-spacing: 0.2px;
					font-size: 20px;
					content: '$';
					display: inline-block;
					position: absolute;
					right: 100%;
					top: 0;
					margin-top: 10px;
					color: #505050;
				}
				&:after {
					letter-spacing: 0.2px;
					font-size: 12px;
					content: 'monthly*';
					display: inline-block;
					position: absolute;
					left: 100%;
					top: 0;
					margin-top: 30px;
					margin-left: 5px;
					color: $table-font;
					font-style: italic;
					font-weight: 300;
				}
			}
		}
		.quote-cell {
			padding: 18px 0;
			background: $table-bckgr;
			a {
				display: inline-block;
				padding: 10px 30px;
				color: $white;
				background: $table-cyan;
				font-weight: 700;
				@include transition(background .4s ease);
				.no-touchevents &:hover {
					background: #2a7887;
				}
			}
		}
	}
	.undef-price {
		.monthly-price {
			padding: 18px 30px;
			span {
				&:before,
				&:after {
					display: none;
				}
			}
		}
	}
}
@media screen and (max-width: 1023px) {
	.price-table {
		.price-col {
			width: 50%;
			&:nth-child(-n+2) {
				margin-bottom: 20px;
			}
			&:nth-child(3n) {
				border-left: 1px solid $table-border;
			} 
		}
	}
}
@media screen and (max-width: $screen-xs) {
	.price-table {
		.price-col {
			margin-bottom: 20px;
			width: 100%;
			border-left: 1px solid $table-border;
			border-bottom: 1px solid $table-border;
			.price-cell:last-child {
				border-bottom: none;
			}
		}
	}
}
//----------------------./price-table----------------
.b-serv-list {
	margin-bottom: 90px;
	padding-top: 14px;
	background: $link-cyan;
	.b-list-wrap {
		background: $feedback-bckgr;
		border-top: 1px solid $white;
		padding-right: 25px;
		padding-top: 20px;
		padding-left: 30px;
		padding-bottom: 35px;
		@media screen and (min-width: 1025px) {
			.first-col {
				width: 29%;
			}
			.second-col {
				width: 23%;
			}
			.third-col {
				width: 31.3333%;
			}
		}
		ul {
			list-style: none;
			.list-title {
				font-size: 14px;
				font-weight: 500;
				color: $menu-font-hover;
				margin-bottom: 10px;
				display: inline-block;
				text-transform: uppercase;
			}
			li {
				line-height: 1.25;
				a {

					font-size: 14px;
					font-weight: normal;
				}
			}
			&:nth-child(n+2)  {
				margin-top: 35px;
			}
		}
	}
}
@media screen and (max-width: 767px) {
	.b-serv-list {
		.b-list-wrap {
			.row {
				div[class^='col-sm'] {
					&:nth-child(n+2) {
						margin-top: 20px;
					}
				}
			}
		}
	}
}